import { useEventListener } from 'ahooks';
import { useInView } from "framer-motion";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { isTablet } from 'react-device-detect';
import { useMedia } from "react-recipes";
import styled from "styled-components/macro";
import { gsap } from "../../gsap";
import { useMergedRefs } from '../../hooks';
import pxToRem from "../Utils/pxToRem";

const OuterWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  overflow: hidden;
  width: ${(props) => props.width};
  position: relative;
  cursor: pointer;

  video {
    object-fit: cover;
  }

  &:hover {
    .videocustom__videomedia {
      &.about {
        svg {
          transform: scale(1);
        }
      }
    }

    .overlay-video {
      opacity: 0.45;
    }

    &.active {
      .videocustom__videocontrol {
        transform: translateY(0%);
      }

      .overlaygradient-video {
        opacity: 0.5;
      }
    }

    &:not(.active) {
      .videocustom__videomedia {
        &:not(.about) {
          .videocustom__videoduration {
            transform: translateY(-101%);
          }
        }
      }

      .videocustom__videoplaytext {
        transform: translateY(0%);
      }
    }
  }

  &.activetemporary {
    .videocustom__videocontrol {
      transform: translateY(0%);
    }
  }

  &.active {
    .videocustom__videobutton {
      transform: scale(0);

      svg {
        transform: translate(-50%, -50%) scale(0);
      }
    }

    .videocustom__videomedia {
      .line {
        transform: translateY(-101%);
      }
    }

    .overlay-video {
      opacity: 0;
    }

    .overlaygradient-video {
      opacity: 0;
    }
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${(props) => props.padding};

  &.fullscreen {
    video {
      pointer-events: auto !important;
    }
  }

  .video-thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    pointer-events: none;
  }
`;

const OverlayVideo = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: #000;
  opacity: 0.15;
  transition: opacity 0.8s var(--transition-ease);
  pointer-events: none;
`;

const OverlayVideoGradient = styled.div`
  width: 100%;
  height: 20%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, black, transparent);
  opacity: 0;
  transition: opacity 0.8s var(--transition-ease);
  transition-delay: 0.2.5s;
  pointer-events: none;

  @media screen and (max-width: 620px) {
    display: none;
  }
`;

const VideoElement = styled.video`
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

const VideoFallback = styled.p`
  padding: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
`;

const VideoMedia = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${pxToRem(11)};
  position: absolute;
  z-index: 1;
  top: ${(props) => props.showDuration ? `calc(50% + ${pxToRem(11)})` : '50%'};
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-family: "Suisse Intl", sans-serif;
  font-weight: 500;
  font-size: ${pxToRem(13)};
  letter-spacing: 0.15em;
  line-height: 1.4;
  pointer-events: none;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(9)};
    top: calc(50% + ${pxToRem(7)});
    gap: ${pxToRem(7)};
  }

  .videocustom__videoduration {
    color: ${(props) => props.playerColorMain || "white"};
  }

  .videocustom__videoplaytext {
    position: absolute;
    inset: 0;
    display: block;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(101%);
    color: ${(props) => props.playerColorMain || "white"};
    transition: transform 0.8s var(--transition-ease);
  }

  .line {
    display: block;
    text-align: center;
    transition: transform 0.8s var(--transition-ease);
  }

  .masking-text {
    position: relative;

    &:nth-child(2) {
      .line {
        transition-delay: 0.05s;
      }
    }

    &:nth-child(3) {
      .line {
        transition-delay: 0.1s;
      }
    }
  }

  &.about {
    .videocustom__videobutton {
      width: ${pxToRem(153)};
      height: ${pxToRem(153)};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${pxToRem(6.7)};

      @media screen and (max-width: 620px) {
        width: ${pxToRem(70)};
        height: ${pxToRem(70)};
        gap: ${pxToRem(3)};
      }

      p {
        font-family: "Suisse Intl", sans-serif;
        font-size: ${pxToRem(11)};
        font-weight: 700;
        letter-spacing: 0.2em;
        line-height: normal;
        text-transform: uppercase;

        @media screen and (max-width: 620px) {
          font-size: ${pxToRem(8)};
        }
      }

      svg {
        position: inherit;
        top: inherit;
        left: inherit;
        transform: inherit;

        @media screen and (max-width: 620px) {
          height: 0.9em;
          top: 0.25px;
        }
      }
    }

    .videocustom__videoduration {
      font-family: "Suisse Intl", sans-serif;
      font-size: ${pxToRem(9.3)};
      font-weight: 700;
      letter-spacing: 0.08em;
      line-height: normal;
      text-transform: uppercase;

      @media screen and (max-width: 620px) {
        font-size: ${pxToRem(8)};
      }
    }
  }
`;

const VideoButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.playerColorMain || "white"};
  width: ${pxToRem(68)};
  height: ${pxToRem(68)};
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  transform-origin: center;
  transition: transform 0.8s var(--transition-ease);
  will-change: transform;

  @media screen and (max-width: 620px) {
    width: ${pxToRem(50)};
    height: ${pxToRem(50)};
  }

  svg {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    transition: transform 0.8s var(--transition-ease);
    transition-delay: 0.2s;
    will-change: transform;
    width: ${pxToRem(9)};
    height: auto;
  }
`;

const VideoControl = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: ${pxToRem(24)} ${pxToRem(30)};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${pxToRem(200)};
  justify-content: space-between;
  box-sizing: border-box;
  will-change: transform;
  transform: translateY(101%);
  transition: transform 0.8s var(--transition-ease);
  transition-delay: 0.2.5s;
  cursor: default;

  @media screen and (max-width: 620px) {
    display: none;
  }
`;

const VideoPause = styled.div`
  display: flex;
  cursor: pointer;
  padding: 1rem;
  margin: -1rem;

  svg {
    height: 100%;
    transition: transform 0.8s var(--transition-ease);
    transform-origin: center;
    will-change: transform;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`;

const VideoProgressWrapper = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
  top: -3px;
  cursor: pointer;
  transition: transform 0.8s var(--transition-ease);
  will-change: transform;

  &:hover {
    transform: scaleY(2);
  }
`;

const VideoProgressFakeSpace = styled.div`
  width: 100%;
  height: 25px;
  position: absolute;
  top: -10px;
  left: 0;
  z-index: 2;
`;

const VideoProgress = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  transform-origin: left;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  will-change: transform;
`;

const VideoFullscreen = styled.div`
  display: flex;
  padding: 1rem;
  margin: -1rem;
  box-sizing: border-box;
  cursor: pointer;

  svg {
    transition: transform 0.8s var(--transition-ease);
    transform-origin: center;
    transition-delay: 0.1s;
    will-change: transform;

    path {
      transform-origin: center;
      transition: transform 0.8s var(--transition-ease);
      will-change: transform;
    }
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`;

const CanvasWrapper = styled.div`
  position: absolute;
  inset: 0 -${pxToRem(50)} -${pxToRem(50)} -${pxToRem(50)};
  opacity: 0.8;
  filter: blur(50px);
  perspective: 1000px;
  overflow: hidden;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  transform: translateY(0) rotateX(32deg);
  transform-origin: 0 100%;
`;

export const VideoCustomPlayerEvents = {
  play: 'videocustomplayer:play',
  pause: 'videocustomplayer:pause'
};

/**
 * @internal
 * @typedef {'pause' | 'play'} VideoCustomPlayerEvent
 */

/**
 *
 * @param {VideoCustomPlayerEvent} type
 * @param {HTMLElement} element
 */
function dispatchEvent(type, element) {
  if (!type || !element) {
    return;
  }

  const event = new Event(`videocustomplayer:${type}`);
  element.dispatchEvent(event);
}

function resolveDispatchCustomEvents(dispatchCustomEvents) {
  if (typeof dispatchCustomEvents === 'boolean') {
    return {
      onPlay: dispatchCustomEvents,
      onPause: dispatchCustomEvents
    };
  }

  return {
    onPlay: dispatchCustomEvents?.onPlay ?? true,
    onPause: dispatchCustomEvents?.onPause ?? true
  };
};

/**
 * @callback VideoCustomPlayerPlaybackFn
 * @argument {object} options
 * @argument {boolean} [options.dispatchCustomEvent=true]
 */

/**
 * @typedef {object} VideoCustomPlayerOutRef
 * @property {VideoCustomPlayerPlaybackFn} play
 * @property {VideoCustomPlayerPlaybackFn} pause
 */

/**
 * @typedef {object} VideoCustomPlayerDispatchCustomEventProps
 * @property {boolean} [onPlay=true] Whether to dispatch the custom event on base play event
 * @property {boolean} [onPause=true] Whether to dispatch the custom event on base pause event
 */

/**
 * @typedef {object} VideoCustomPlayerPartialProps
 * @property {string} [aspect] Aspect ratio of the video player
 * @property {boolean | VideoCustomPlayerDispatchCustomEventProps} dispatchCustomEvent Whether to dispatch custom events on base events
 * @property {import('react').Ref<VideoCustomPlayerOutRef>} outRef
 * @property {boolean} [posterVideo=false] Whether to show a video poster
 * @property {boolean} [showDuration=true] Whether to show the video duration
 * @property {boolean} [withDropshadow=false] Whether to show a dropshadow on the video player
 * @property {boolean} [withViewportObserver=true] Whether to use the viewport observer
 */

/**
 * @typedef {import('react').ComponentProps<'video'> & VideoCustomPlayerPartialProps} VideoCustomPlayerProps
 */

/** @type {import('framer-motion').ForwardRefComponent<HTMLVideoElement, VideoCustomPlayerProps>} */
const VideoCustomPlayer = forwardRef((props, ref) => {
  const {
    outRef,
    dispatchCustomEvent,
    posterVideo,
    showDuration = true,
    withDropshadow = false,
    withViewportObserver = true
  } = props;

  const rootRef = useRef(null);
  const captureRootRef = useMergedRefs(rootRef, ref);
  const refWrapper = useRef(null);
  const _dispatchCustomEvent = resolveDispatchCustomEvents(dispatchCustomEvent);

  /** @type {import('react').MutableRefObject<HTMLVideoElement>} */
  const refVideo = useRef(null);
  const elVideoProgress = useRef(null);
  const inView = useInView(refWrapper, { amount: 0.25 });

  const [play, setPlay] = useState(false);
  const [firstPlay, setFirstPlay] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [textVideoDuration, setTextVideoDuration] = useState(null);
  const wasPlaying = useRef(false);

  const [isReady, setReady] = useState({ videoRedy: false, posterReady: false });
  const canPlaythrough = isReady.videoRedy && (posterVideo ? isReady.posterReady : true);

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Use template literals to add leading zeros if needed
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const goFullScreen = () => {
    if (refVideo.current) {
      if (refVideo.current.requestFullscreen) {
        refVideo.current.requestFullscreen();
      } else if (refVideo.current.webkitRequestFullscreen) {
        refVideo.current.webkitRequestFullscreen();
      } else if (refVideo.current.mozRequestFullScreen) {
        refVideo.current.mozRequestFullScreen();
      } else if (refVideo.current.msRequestFullscreen) {
        refVideo.current.msRequestFullscreen();
      }
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const loadMetadata = useCallback(() => {
    if (!refVideo.current) return;
    setTextVideoDuration(formatTime(refVideo.current.duration));
  }, []);

  const timeUpdate = useCallback(() => {
    if (!refVideo.current) return;
    setTextVideoDuration(
      formatTime(refVideo.current.duration - refVideo.current.currentTime)
    );
    gsap.to(elVideoProgress.current, {
      scaleX: refVideo.current.currentTime / refVideo.current.duration,
      duration: 0.5,
      ease: "none",
    });
  }, []);

  /** @type {VideoCustomPlayerPlaybackFn} */
  const pauseVideo = useCallback(({ dispatchCustomEvent } = { dispatchCustomEvent: true }) => {
    if (!refVideo.current || !play) return;
    refVideo.current.pause();
    setPlay(false);

    if (!isMobile && firstPlay) {
      refWrapper.current.classList.remove("active");

      if (isTablet) {
        refWrapper.current.classList.remove("activetemporary");
      }
    }

    if (dispatchCustomEvent) {
      dispatchEvent('pause', rootRef.current);
    }
  }, [rootRef, play, isMobile, firstPlay]);

  /** @type {VideoCustomPlayerPlaybackFn} */
  const playVideo = useCallback(({ dispatchCustomEvent } = { dispatchCustomEvent: true }) => {
    if (!refVideo.current || play) return;
    refVideo.current.play();
    setPlay(true);
    refWrapper.current.classList.add("active");

    if (isTablet) {
      refWrapper.current.classList.add("activetemporary");

      setTimeout(() => {
        refWrapper.current.classList.remove("activetemporary");
      }, 2000);
    }

    if (!firstPlay) {
      setFirstPlay(true);
    }

    if (dispatchCustomEvent) {
      dispatchEvent('play', rootRef.current);
    }
  }, [rootRef, play, firstPlay]);

  const pickDuration = useCallback((e) => {
    if (!elVideoProgress.current || !refVideo.current) return;
    const progressWidth = elVideoProgress.current.offsetWidth;
    const offsetX = e.nativeEvent.offsetX;
    const percent = offsetX / progressWidth;
    refVideo.current.currentTime = percent * refVideo.current.duration;
  }, []);

  const resetPlayerState = useCallback(() => {
    if (refWrapper.current) {
      refWrapper.current.classList.remove("active");
    }
    setPlay(false);
    setIsFullscreen(false);
  }, []);

  useEffect(() => {
    const videoElement = refVideo.current;

    const handleFullScreenChange = () => {
      const isFullscreenNow =
        (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement) === videoElement;

      setIsFullscreen(isFullscreenNow);

      if (!isFullscreenNow) {
        // resetPlayerState();
        setTimeout(() => {
          if (refWrapper.current) {
            refWrapper.current.style.pointerEvents = "auto";
          }
        }, 100);
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, [resetPlayerState]);

  useEffect(() => {
    if (!withViewportObserver || isFullscreen || (!navigator?.userActivation?.hasBeenActive ?? false)) {
      return;
    }

    if (inView && refVideo.current.paused && wasPlaying.current) {
      refVideo.current.play();
      wasPlaying.current = false;
      return;
    }

    if (!inView && !refVideo.current.paused) {
      refVideo.current.pause();
      wasPlaying.current = true;
    }
  }, [inView, isFullscreen, withViewportObserver]);

  const handleVideoInteraction = useCallback(
    (e) => {
      e.preventDefault();
      if (!isFullscreen) {
        play ? pauseVideo() : playVideo();
      }
    },
    [isFullscreen, play, pauseVideo, playVideo]
  );

  /** @type {import('react').MutableRefObject<HTMLCanvasElement>} */
  const canvasRef = useRef(null);
  /** @type {import('react').MutableRefObject<HTMLVideoElement>} */
  const posterVideoRef = useRef(null);

  useEffect(() => {
    if (!withDropshadow || !canPlaythrough) {
      return;
    }

    const elementRef = posterVideo && !firstPlay
      ? posterVideoRef.current
      : refVideo.current;

    if (!(elementRef instanceof HTMLVideoElement)) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = elementRef.videoWidth;
    canvas.height = elementRef.videoHeight;

    let rId = 0;

    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(elementRef, 0, 0, canvas.width, canvas.height);
      rId = requestAnimationFrame(draw);
    };

    const onPlay = () => {
      draw();
    };

    const onPause = () => {
      cancelAnimationFrame(rId);
    }

    elementRef.addEventListener("play", onPlay);
    elementRef.addEventListener('pause', onPause);

    return () => {
      cancelAnimationFrame(rId);
      elementRef.removeEventListener("play", onPlay);
      elementRef.removeEventListener('pause', onPause);
    };
  }, [canPlaythrough, firstPlay, posterVideo, withDropshadow]);

  useEventListener(
    'canplaythrough',
    useCallback(() => setReady((prev) => ({ ...prev, videoRedy: true })), []),
    {
      target: refVideo
    }
  );

  useEventListener(
    'canplaythrough',
    useCallback(() => setReady((prev) => ({ ...prev, posterReady: true })), []),
    {
      target: posterVideoRef
    }
  );

  useImperativeHandle(outRef, () => ({
    play: playVideo,
    pause: pauseVideo
  }), [pauseVideo, playVideo]);

  return (
    <OuterWrapper ref={captureRootRef}>
      {withDropshadow && (
        <CanvasWrapper>
          <Canvas ref={canvasRef} />
        </CanvasWrapper>
      )}

      <Wrapper
        ref={refWrapper}
        className={props.className}
        width={props.width}
        style={{ "--aspect": props.aspect || "16/9" }}
      >
        <VideoWrapper
          className={isFullscreen && "fullscreen"}
          padding={props.padding}
          onClick={handleVideoInteraction}
          onTouchEnd={handleVideoInteraction}
        >
          {props.posterVideo && !firstPlay && (
            <video
              ref={posterVideoRef}
              className="video-thumbnail"
              autoPlay
              muted
              loop
              playsInline
              preload="metadata"
            >
              <source src={props.poster} type="video/mp4" />
            </video>
          )}

          <OverlayVideo className="overlay-video" />
          <OverlayVideoGradient className="overlaygradient-video" />
          <VideoElement
            ref={refVideo}
            autoPlay={props.autoPlay ?? false}
            loop={props.loop ?? true}
            controls={props.controls ?? false}
            preload={props.preload ?? "metadata"}
            poster={props.posterVideo ? "" : props.poster}
            onPlay={() => playVideo({ dispatchCustomEvent: _dispatchCustomEvent.onPlay })}
            onPause={() => pauseVideo({ dispatchCustomEvent: _dispatchCustomEvent.onPause })}
            onLoadedMetadata={() => loadMetadata()}
            onTimeUpdate={() => (!isMobile ? timeUpdate() : undefined)}
          >
            <source src={props.src} type={props.type ?? "video/mp4"} />
            <VideoFallback>
              {props.fallbackMessage ??
                "Your browser does not support the video element. Please consider updating to a modern browser."}
            </VideoFallback>
          </VideoElement>
        </VideoWrapper>

        {!props.about && (
          <VideoMedia
            className="videocustom__videomedia"
            showDuration={showDuration}
          >
            <VideoButton
              className="videocustom__videobutton"
              playerColorMain={props.playerColorMain}
            >
              <svg
                width="9"
                height="13"
                viewBox="0 0 9 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.65381 6.20321L0.153808 12.164L0.153809 0.242446L8.65381 6.20321Z"
                  fill={props.playerIconColor || "black"}
                />
              </svg>
            </VideoButton>

            {showDuration && (
              <div className="masking-text">
                <p className="videocustom__videoduration line">
                  {textVideoDuration ?? "00:00"}
                </p>
                <p className="videocustom__videoplaytext">Play</p>
              </div>
            )}
          </VideoMedia>
        )}

        {props.about && (
          <VideoMedia className="videocustom__videomedia about">
            <VideoButton
              className="videocustom__videobutton"
              playerColorMain={props.playerColorMain}
            >
              <p>Play</p>
              <svg
                width="7"
                height="9"
                viewBox="0 0 7 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.0234375 0.552734V8.75296L6.17361 4.65285L0.0234375 0.552734Z"
                  fill="#F75703"
                />
              </svg>
            </VideoButton>

            {showDuration && (
              <p className="videocustom__videoduration">
                <span className="masking-text">
                  <span className="line">© 2016-22</span>
                </span>
                <span className="masking-text">
                  <span className="line">enjoy in ours &</span>
                </span>
                <span className="masking-text">
                  <span className="line">yours 57’ sec</span>
                </span>
              </p>
            )}
          </VideoMedia>
        )}

        <VideoControl className="videocustom__videocontrol">
          <VideoPause onClick={() => pauseVideo()}>
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="3.27216e-08"
                x2="0.999999"
                y2="13"
                stroke="white"
                strokeWidth="2"
              />
              <line
                x1="10"
                y1="3.27216e-08"
                x2="10"
                y2="13"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </VideoPause>

          <VideoProgressWrapper onClick={(e) => pickDuration(e)}>
            <VideoProgressFakeSpace />
            <VideoProgress
              className="videocustom__videoprogress"
              ref={elVideoProgress}
            />
          </VideoProgressWrapper>

          <VideoFullscreen
            className="videocustom__fullscreen"
            onClick={isFullscreen ? exitFullScreen : goFullScreen}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.14286 9.64286H0V15H5.35714V12.8571H2.14286V9.64286ZM0 5.35714H2.14286V2.14286H5.35714V0H0V5.35714ZM12.8571 12.8571H9.64286V15H15V9.64286H12.8571V12.8571ZM9.64286 0V2.14286H12.8571V5.35714H15V0H9.64286Z"
                fill="white"
              />
            </svg>
          </VideoFullscreen>
        </VideoControl>
      </Wrapper>
    </OuterWrapper>
  );
});

export default VideoCustomPlayer;
