import { useEventListener, useInViewport, useMount, useMouse, useTimeout } from 'ahooks';
import classNames from 'classnames';
import { AnimatePresence, motion, useIsPresent } from 'framer-motion';
import normalizeWheel from 'normalize-wheel-es';
import React, { useCallback, useMemo, useRef, useState } from "react";
import { isDesktop, isMacOs, isMobile, isTablet } from 'react-device-detect';
import { useDimensions } from 'react-recipes';
import { Easings } from '../animation';
import Appear from '../components/Appear';
import Card from '../components/Card';
import { CardMore } from '../components/Card/CardMore';
import { CardSharedTrasitionsContextProvider } from '../components/Card/contexts/cardSharedTrasitionContext';
import Container from '../components/Container';
import { Counter } from '../components/Counter';
import { DelayRender } from '../components/DelayRender/DelayRender';
import { Flex } from '../components/Flex';
import MaskedReveal from '../components/Motion/MaskedReveal';
import Page from '../components/Page';
import { Select } from '../components/Select';
import { SiteHeaderMain } from '../components/SiteHeader';
import { gsap } from '../gsap';
import { withRegisterRoute } from '../routing';
import { Projects, Tags } from '../worksData';
import './Works.scss';

function getProjectCount() {
  return Projects.filter(({ legacy }) => !legacy).length;
}

const legacyProjectsCount = Projects.filter(({ legacy }) => legacy).length;

const ProjectsCount = React.forwardRef((_, ref) => {
  /** @type {import('../components/Counter').CounterProps['ref']} */
  const counterRef = useRef(null);
  const projectCount = getProjectCount();
  const startValue = isMacOs ? projectCount : 1;

  useTimeout(
    () => counterRef.current?.startAnimation(),
    1000
  );

  return (
    <div
      ref={ref}
      className="no"
    >
      &#40;
      <span>
        <Counter
          ref={counterRef}
          duration={1.4}
          startValue={startValue}
          value={projectCount}
        />
      </span>
      &#41;
    </div>
  );
});

ProjectsCount.displayName = 'ProjectsCount';

function Title({ root, isMobile }) {
  const elRef = useRef();
  const isPresent = useIsPresent();
  const [inView] = useInViewport(elRef, { root: () => root.current });

  if (!isPresent && !inView && !isMobile) {
    return null;
  }

  return (
    <div className="title" ref={elRef}>
      <MaskedReveal
        className="lineMask parallax__layer"
        delay={[0, 0]}
        style={{ '--parallax-speed': -1 }}
      >
        <div
          style={{ display: 'flex', alignItems: 'flex-start' }}
        >
          Visual
          <MaskedReveal delay={[0, 0]}>
            <ProjectsCount />
          </MaskedReveal>
        </div>
      </MaskedReveal>

      <MaskedReveal
        className="lineMask parallax__layer"
        delay={[0.3, 0]}
        style={{ '--parallax-speed': 3 }}
      >
        eloquence.
      </MaskedReveal>
    </div>
  )
}

function Works() {
  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const containerRef = useRef();

  const [tagFilter, setTagFilter] = useState(Tags[0]);
  const [areLegacyProjectsVisible, setLegacyProjectsVisibility] = useState(false);
  const [contentRef, contentElSize] = useDimensions();
  const { clientX: x, clientY: y } = useMouse();

  const [showHint, setShowHint] = useState(true);
  const isPresent = useIsPresent();
  const [shouldRenderDropdownInHeader, setShouldRenderDropdownInHeader] = useState(false);

  /** @type {import('framer-motion').Variants} */
  const hintVariants = useMemo(() => ({
    enter: ([{ delay }]) => ({
      scale: 1,
      transition: {
        delay,
        ease: Easings.easeOutCubic
      }
    }),
    exit: ([, { delay } = {}]) => ({
      scale: 0,
      transition: {
        delay,
        ease: Easings.easeInCubic
      }
    })
  }), []);

  const title = (
    <Title root={containerRef} isMobile={isMobile} />
  );

  const handleLegacyProjectsVisibilityChange = useCallback(() => {
    setLegacyProjectsVisibility(true);
  }, []);

  const dropdown = useMemo(() => {
    return (
      <Select
        label="Industry"
        options={Tags}
        value={tagFilter.value}
        onChange={(e) => {
          // eslint-disable-next-line eqeqeq
          const tag = Tags.find(({ value }) => value == e);
          setTagFilter(tag ?? Tags[0]);
        }}
      />
    );
  }, [tagFilter.value]);

  const filteredProjects = useMemo(() => {
    if (tagFilter.label === 'All') {
      return (
        Projects
          .filter(({ legacy }) => !legacy || (legacy && areLegacyProjectsVisible))
      );
    }

    return (
      Projects
        .filter(({ tags, legacy }) => (
          tags.includes(tagFilter.label))
          && (!legacy || (legacy && areLegacyProjectsVisible))
        )
    );
  }, [areLegacyProjectsVisible, tagFilter]);

  const isCardMoreVisible = useMemo(() => (
    !areLegacyProjectsVisible && (Projects.some(({ legacy, tags }) => legacy && tags.includes(tagFilter.label)) || tagFilter.label === 'All')
  ), [areLegacyProjectsVisible, tagFilter.label]);

  const handleScroll = useCallback((e) => {
    if (isTablet || isDesktop) {
      const ratio = e.target.scrollLeft / e.target.clientWidth;
      if (!shouldRenderDropdownInHeader && ratio >= 0.25) {
        setShouldRenderDropdownInHeader(true);
        return;
      }

      if (shouldRenderDropdownInHeader && ratio < 0.25) {
        setShouldRenderDropdownInHeader(false);
      }
    }
  }, [shouldRenderDropdownInHeader]);

  const prevTimestamp = useRef(0);
  const multiplier = useRef(1);

  useEventListener(
    'wheel',
    useCallback((e) => {
      const el = containerRef.current;
      const delta = (normalizeWheel(e)?.pixelY ?? 0) * 1.2;

      multiplier.current = e.timeStamp - prevTimestamp.current < 250
        ? multiplier.current + 0.5
        : 1;

      gsap.to(el, {
        scrollTo: {
          autoKill: true,
          x: `+=${delta * gsap.utils.clamp(1, 2.8, multiplier.current)}`,
        },
        // duration: 1,
        ease: 'pow1.out'
      });

      prevTimestamp.current = e.timeStamp;
    }, [])
  );

  useEventListener(
    'touchmove',
    useCallback(() => setShowHint(false), [])
  );

  useMount(() => {
    setTimeout(() => {
      if (containerRef.current instanceof HTMLElement) {
        containerRef.current.scrollLeft = 0;
      }
    }, 0);
  });

  return (
    <CardSharedTrasitionsContextProvider>
      <Page>
        {(isMobile && !isTablet) && (
          <MaskedReveal className="Work__dropdown-wrapper Work__dropdown-wrapper--mobile">
            {React.cloneElement(dropdown, { ref: null })}
          </MaskedReveal>
        )}

        <Container
          className="Work"
          size="l"
          ref={containerRef}
          style={{ '--content-height': contentElSize?.height ? `${contentElSize.height}px` : 'max-content' }}
          onScroll={handleScroll}
        >
          <div className="Work__content-wrapper" ref={contentRef}>
            <div
              className={classNames([
                'Work__slide',
                'Work__slide-title'
              ])}
            >
              <div className="Work__dropdown-wrapper">
                <AnimatePresence>
                  {isPresent && (isTablet || isDesktop) && !shouldRenderDropdownInHeader && (
                    <MaskedReveal>
                      {dropdown}
                    </MaskedReveal>
                  )}
                </AnimatePresence>
              </div>

              {title}
            </div>

            {filteredProjects.map(({ id, ...cardProps }, idx) => {
              return (
                <div
                  key={cardProps.href}
                  className={classNames([
                    'Work__slide',
                    'Work__slide-card'
                  ])}
                >
                  <Appear
                    disabled
                    reverse={false}
                    // trigger={isMobile ? 'mount' : 'inViewport'}
                    root={containerRef.current}
                  >
                    <Card
                      {...cardProps}
                      cursorPosition={{ x, y }}
                      index={idx}
                      isMobile={isMobile}
                      root={containerRef.current}
                    />
                  </Appear>
                </div>
              );
            })}

            {isCardMoreVisible && (
              <div
                key={filteredProjects.length}
                className={classNames([
                  'Work__slide',
                  'Work__slide-card'
                ])}
              >
                <Appear
                  disabled
                  reverse={false}
                >
                  <CardMore
                    index={filteredProjects.length}
                    projectCount={legacyProjectsCount}
                    title="OLDER WORK"
                    onClick={handleLegacyProjectsVisibilityChange}
                  />
                </Appear>
              </div>
            )}
          </div>

          {isMobile && (
            <AnimatePresence>
              {showHint && isPresent && (
                <motion.div
                  custom={[{ delay: 1.2 }, { delay: 0 }]}
                  initial="exit"
                  animate={showHint ? 'enter' : 'exit'}
                  exit="exit"
                  variants={hintVariants}
                  className="Drag__Works"
                >
                  <span>Drag</span>
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </Container>

        <AnimatePresence>
          {isPresent && (isTablet || isDesktop) && shouldRenderDropdownInHeader && (
            <SiteHeaderMain.Source>
              <Flex className="Work__dropdown-cloneWrapper">
                <DelayRender>
                  <MaskedReveal
                    duration={0.5}
                    className="Work__dropdown-wrapper"
                  >
                    {React.cloneElement(dropdown, { ref: null })}
                  </MaskedReveal>
                </DelayRender>
              </Flex>
            </SiteHeaderMain.Source>
          )}
        </AnimatePresence>
      </Page>
    </CardSharedTrasitionsContextProvider>
  );
}

export default withRegisterRoute(Works, {
  route: '/work',
  name: () => (
    <span>Work<sup>&#40;{getProjectCount()}&#41;</sup></span>
  ),
  description: 'Our portfolio covers the full range of applied strategy, web design, content development and branding services. Premium, fully customized design for clients across a wide range of global industries.',
  jsonld: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    'name': 'FIFTYSEVEN',
    'description': 'Our portfolio covers the full range of applied strategy, web design, content development and branding services. Premium, fully customized design for clients across a wide range of global industries.',
    'url': 'https://fiftyseven.co/work',
    'image': {
      '@type': 'ImageObject',
      'url': 'https://www.fiftyseven.co/thumbnail/work.png',
      'width': 1200,
      'height': 628
    }
  },
  weight: 5,
  options: {
    transition: (_, to) => {
      // if (to.route.startsWith('/case')) {
      // return 'sync'
      // }
      return 'wait';
    },
    navBackOptions: { mobile: true },
    layoutGuard: {
      mobile: { landscape: true }
    }
  }
});
