import { useRequest, useUnmount } from "ahooks";
import classNames from "classnames";
import { motion, useIsPresent } from "framer-motion";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Durations } from "../../animation";
import { SiteConfigurationAPI } from "../../api/siteConfiguration";
import { useNormalizeValue } from "../../hooks";
import { MaskedReveal } from "../Motion";
import { MotionFlex } from "../MotionFlex";
import classes from "./BusinessInquiry.module.scss";

/**
 * @typedef {object} BusinessInquiryProps
 * @property {string} [className]
 * @property {number | [number, number]} [delay]
 * @property {boolean} [isBook]
 * @property {boolean} withProgress
 */

/** @type {import("framer-motion").Variants} */
const indicatorVariants = {
  default: ({ available }) => ({
    scale: [null, 1],
    backgroundColor: [null, available ? "#26ff00" : "#EB0000"],
  }),
  loading: {
    scale: [0, 1, 0],
    transition: {
      duration: Durations.base,
      delay: 0.4,
      repeat: Infinity,
      repeatType: "mirror",
    },
  },
};

/** @type {import('react').FC<BusinessInquiryProps>} */
export const BusinessInquiry = (props) => {
  const { isBook = false, withProgress = false } = props;

  const isPresent = useIsPresent();
  const location = useLocation();

  const [delayEnter, delayExit] = useNormalizeValue(props.delay ?? 0);
  let { loading, data, cancel } = useRequest(SiteConfigurationAPI.get, {
    debounceWait: delayEnter * 1000,
  });

  useUnmount(() => {
    cancel();
  });

  const routerState = useMemo(
    () => ({
      from: location.pathname,
    }),
    [location]
  );

  if (loading && !withProgress) {
    return null;
  }

  return (
    <MotionFlex
      className={classNames([classes.root, props.className])}
      layout="preserve-aspect"
    >
      <MaskedReveal delay={[0, delayExit]}>
        <motion.div
          animate={loading ? "loading" : "default"}
          className={`businessinquiry-indicator ${classes.statusIndicator}`}
          custom={{
            available: !!data?.businessInquiries,
          }}
          variants={indicatorVariants}
        />
      </MaskedReveal>

      {isPresent && !loading && !isBook && data?.businessInquiries && (
        <>
          <MaskedReveal delay={[0.5, delayExit]}>
            <div className={`businessinquiry-text ${classes.text}`}>
              new business inquiries
            </div>
          </MaskedReveal>

          <MaskedReveal delay={[0.6, delayExit]}>
            <div className={`businessinquiry-badge ${classes.badge}`}>
              {data?.businessInquiries}
            </div>
          </MaskedReveal>
        </>
      )}

      {isPresent && !loading && !isBook && !data?.businessInquiries && (
        <MaskedReveal delay={[0.5, delayExit]}>
          <div className={`businessinquiry-text ${classes.text}`}>
            We are currently not accepting new business inquiries
          </div>
        </MaskedReveal>
      )}

      {isPresent && props.isBook && (
        <div className="businessinquiry-wrapper">
          <MaskedReveal delay={[0.1, delayExit]}>
            <div className={`businessinquiry-text ${classes.text}`}>
              new business inquiries
            </div>
          </MaskedReveal>

          <div className="businessinquiry-cta">
            <MaskedReveal delay={[0.2, delayExit]}>
              <div className={`businessinquiry-badge ${classes.badge}`}>
                {data?.businessInquiries}
              </div>
            </MaskedReveal>

            {props.isBook && (
              <MaskedReveal delay={[0.3, delayExit]}>
                <Link className={`businessinquiry-book ${classes.book}`} to="/contact" state={routerState}>
                  Book Call
                </Link>
              </MaskedReveal>
            )}
          </div>
        </div>
      )}
    </MotionFlex>
  );
};
